import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import StartNav from '../../components/StartNav'
import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const GameErrorTemplate = (props) => {
  const classes = useStyles(props)
  const { className, title, text, startNavProps } = props

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current && text) {
      const animatedChildren = $animatedWrapper.current.children

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [text])

  return (
    <div
      className={cx(className, classes.container)}
      ref={$animatedWrapper}
    >
      <h1 className={classes.title}>
        {title}
      </h1>
      {text && (
        <div className={classes.text}>
          {text}
        </div>
      )}
      {startNavProps && (
        <StartNav
          className={classes.nav}
          {...startNavProps}
        />
      )}
    </div>
  )
}

export const GameErrorTemplatePropTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.string,
  startNavProps: PropTypes.shape(StartNav.PropTypes),
}

GameErrorTemplate.propTypes = GameErrorTemplatePropTypes

GameErrorTemplate.defaultProps = {
  className: null,
  title: '',
  text: null,
  startNavProps: null,
}

export default withMemo()(GameErrorTemplate)
