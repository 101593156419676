import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import { useIntl } from '../../intl/intl'
import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import LayoutGame from '../../components/LayoutGame'
import GameErrorTemplate from '../../templates/GameError'
import routes from '../../router/routes'
import router from '../../router'
import useLocalStorage from '../../hooks/useLocalStorage'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'


const GameError = (props) => {
  const { pageContext, location } = props
  const t = useIntl()
  const { resetError, userErrorReason } = useLocalStorage()
  const { seoFallback } = useConfiguration()

  const layoutProps = useMemo(() => ({
    withHeader: false,
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
      robots: 'noindex, follow',
    },
  }), [location.href, pageContext.language, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, seoFallback.title])

  const gamePageErrorProps = useMemo(() => ({
    title: t('error_title'),
    text: userErrorReason ? t(`error_message_${userErrorReason}`) : '',
  }), [t, userErrorReason])

  const startNavProps = {
    buttons: [
      {
        text: t('try_again'),
        variant: 'bigtransparent',
        onClick: () => {
          resetError()
          navigate(router(pageContext?.isVip ? routes.vipGame.path : routes.game.path))
        },
      },
    ],
  }

  return (
    <LayoutGame {...layoutProps}>
      <GameErrorTemplate
        startNavProps={startNavProps}
        {...gamePageErrorProps}
      />
    </LayoutGame>
  )
}

GameError.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

GameError.defaultProps = {
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(GameError)))
