import { createUseStyles } from 'react-jss'

import { desktop, maxDesktop, colors } from '../../theme'


export default createUseStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    minHeight: 'calc(var(--vh, 1vh) * 50 - 1.8rem)', // half screen minus half height of "title"
    fallbacks: {
      minHeight: 'calc(49vh - 1.8rem)',
    },
    '& > *': {
      width: '100%',
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '2rem',
    },
    '& > * + $text': {
      marginTop: 'auto',
    },
    '& > *:not($text) + $nav': {
      marginTop: 'auto',
    },
    ...maxDesktop({
      '& > * + $text:not(:last-child)': {
        marginBottom: '5rem', // added to avoid having the text stick to the nav....
      },
    }),
  },
  header: {},
  title: {
    fontSize: '3.6rem',
    textTransform: 'uppercase',
    color: colors.redError,
    fontWeight: 'normal',
    textAlign: 'center',
    letterSpacing: '0.2rem',
  },
  // svg removed
  // logo: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   marginbottom: '2rem',
  // },
  // logoGameError: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   width: '14.4rem',
  //   height: '3.2rem',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  // },
  text: {
    maxWidth: '40rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '8rem',
    fontSize: '1.2rem',
    lineHeight: '1.2',
    textAlign: 'center',
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '0.5rem',
    },
    ...desktop({
      '& > * + *': {
        marginTop: '1rem',
      },
    }),
  },
  nav: {},
})
